<template>
	<div class="urlInfo">
    <div class="titles">
			<span class="text">用户确认</span>
			<slot name="btn"></slot>
		</div>
		<div class="img-box">
		
			<div v-for="(img,index) in [...data,...phot]" :key="img" class="img">
				<el-image
            style="
									width: 100px;
									height: 100px;
									border: 1px solid #dddddd;
									margin: 5px;
								"
					:src="img"
					:preview-src-list="[...data,...phot]"
					:z-index='index'
				>
				</el-image>
			</div>
      <!-- <div v-for="(img,index) in phot" :key="img" class="img">
				<el-image
            style="
									width: 100px;
									height: 100px;
									border: 1px solid #dddddd;
									margin: 5px;
								"
					:src="img"
					:z-index='index'
					:preview-src-list="phot"
				>
				</el-image>
			</div> -->
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			data: {
				type: Array,
				default: () => [],
			},
			phot: {
				type: Array,
				default: () => [],
			},
		},
	};
</script>

<style scoped lang="less">
	.urlInfo {
		.titles {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 5px;
			.text {
				font-size: 16px;
				font-weight: bolder;
			}
		}
		.img-box {
			display: flex;margin-top:20px;
      flex-wrap: wrap;
			.img {
				margin-right: 10px;
			}
		}
	}
</style>